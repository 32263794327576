import React, { useState, useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import tw from 'twin.macro'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { parseACF, renderLink } from '../utils'
import { Layout, Hero, ContactForm } from '../components'
import { media } from '../styles/utils'
import { container, padding, bgImage, textDot } from '../styles/global'
import { use100vh } from 'react-div-100vh'

import { PageTitleContext } from '../context/PageTitleContext'
import { useMount } from 'react-use'
import { getGlobalData } from '../utils'

import { headerHeight } from '../components/Header/Header'

const Contact = props => {
    const { previewData, pageContext } = props
    const { global: global_data } = getGlobalData()
    const graphQuery = useStaticQuery(query)
    const data = previewData || parseACF(graphQuery, 'allWordpressInfopages')
    const { pageTitle, setPageTitle } = useContext(PageTitleContext)
    const screenHeight = use100vh()
    // console.log(global_data)

    useMount(() => {
        setPageTitle(graphQuery?.allWordpressInfopages?.nodes?.[0]?.title)
    })

    const renderLocations = () => {
        const { locations } = global_data
        if (!locations || locations.length === 0) return
        console.log(locations)

        const items = locations.map((item, index) => {
            const { location, address, phone_number } = item
            return (
                <LocationItem
                    key={index}
                >
                    <LocationGrid>
                        {location && (
                            <div>
                                {location}
                            </div>
                        )}
                        {address && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: address,
                                }}
                            />
                        )}
                    </LocationGrid>
                    {phone_number && (
                        <LocationGrid>
                            <div/>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: phone_number,
                                }}
                            />
                        </LocationGrid>
                    )}
                </LocationItem>
            )
        })

        return (
            <div>
                <div>
                    LOCATIONS
                </div>
                {items}
            </div>
        )
    }

    return (
        <Layout
            meta={data && data.seo}
            title={'Contact'}
        >
            <Wrapper>
                <HeroSizer
                    style={{
                        height: screenHeight - 80,
                    }}
                >
                    <Hero
                        media_type={data?.hero_media_type}
                        image={data?.hero_image}
                        video={data?.hero_video}
                        content={data?.hero_content}
                        largeText={true}
                    />
                </HeroSizer>
                <Content>
                    <Container>
                        <Grid>
                            <Left>
                                <Heading>{global_data?.contact_email}</Heading>
                            </Left>
                            <Right>
                                {renderLocations()}
                            </Right>
                        </Grid>
                        <PageBreak />
                        <Grid>
                            <Left>
                                <Heading>Please fill out the form</Heading>
                            </Left>
                            <Right>
                                <FormWrapper>
                                    <ContactForm />
                                </FormWrapper>
                            </Right>
                        </Grid>
                    </Container>
                </Content>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``

const Icon = styled.img``

// Layout

const LocationItem = styled.div`
    ${tw`mt-12`};
`

const LocationGrid = styled.div`
    ${tw`flex justify-between border-t border-b border-darkGrey py-3`};
    margin-top: -1px;

    > *:last-child {
        min-width: 40%;
    }

    ${media.tablet`
        > *:last-child {
            min-width: 50%;
        }
    `}

    ${media.phone`
        ${tw`flex-col`};

        > *:last-child {
            min-width: 100%;
        }
    `}
`

const Wrapper = styled.div`
    ${tw`w-full`};

    .hero-section {
        ${tw`border-b border-darkGrey`};
    }
`

const Container = styled.div`
    ${container};
    ${padding};
`

const Left = styled.div``
const Right = styled.div``

const Grid = styled.div`
    ${tw`flex w-full`};

    ${media.phone`
        ${tw`flex-col`};
    `}

    ${Left},
    ${Right} {
        ${tw`w-1/2`};

        ${media.phone`
            ${tw`w-full`};
        `}
    }

    ${Left} {
        padding-right: 1.5rem;

        ${media.phone`
            margin-bottom: 3rem;
            padding-right: 0;
        `}
    }

    ${Right} {
        padding-left: 1.5rem;

        ${media.phone`
            padding-left: 0;
        `}
    }
`

// hero

const HeroSizer = styled.div`
    ${tw`relative`};
`

// Content

const Content = styled.div`
    margin-top: 1.875em;
    margin-bottom: 5rem;

    ${Heading} {
        ${textDot}
    }

    ${Description} {
        margin-top: 1em;
    }
`

// Page Break

const PageBreak = styled.div`
    margin-top: 3rem;
    margin-bottom: 3rem;
    ${tw`border-t border-darkGrey`};
`

// Contact Form

const FormWrapper = styled.div`
    
`


export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "contact" } }) {
            nodes {
                title
                acf_json
            }
        }
    }
`

export default Contact
